import React, {useState, useEffect} from 'react';
import './gallery.scss';
import styled from 'styled-components';

//images
// import c11 from '../../../Images/Interior/101.jpg';
// import c12 from '../../../Images/Interior/102.jpg';
// import c13 from '../../../Images/Interior/107.jpg';
// import c14 from '../../../Images/Interior/104.jpg';
// import c15 from '../../../Images/Interior/105.jpg';
// import c16 from '../../../Images/Interior/106.jpg';

// import c21 from '../../../Images/Interior/103.jpg';
// import c22 from '../../../Images/Interior/108.jpg';
// // import c23 from '../../../Images/Interior/109.jpg';
// import c24 from '../../../Images/Interior/110.jpg';
// import c25 from '../../../Images/Interior/111.jpg';
// import c26 from '../../../Images/Interior/112.jpg';
// import c27 from '../../../Images/Interior/113.jpg';

// import c31 from '../../../Images/Interior/114.jpg';
// import c32 from '../../../Images/Interior/115.jpg';
// import c33 from '../../../Images/Interior/116.jpg';
// import c34 from '../../../Images/Interior/117.jpg';
// import c35 from '../../../Images/Interior/118.jpg';
// // import c36 from '../../../Images/Interior/101.jpg';
// // import c37 from '../../../Images/Interior/101.jpg';


import a1 from '../../../Images/Interior/301.jpg'
import a2 from '../../../Images/Interior/302.jpg'
import a3 from '../../../Images/Interior/303.jpg'
import b1 from '../../../Images/Interior/304.jpg'
import b2 from '../../../Images/Interior/305.jpg'
import b3 from '../../../Images/Interior/306.jpg'
import c1 from '../../../Images/Interior/307.jpg'
import c2 from '../../../Images/Interior/308.jpg'
import c3 from '../../../Images/Interior/309.jpg'

import a21 from '../../../Images/Interior/401.jpg'
import a22 from '../../../Images/Interior/402.jpg'
import a23 from '../../../Images/Interior/403.jpg'
import b21 from '../../../Images/Interior/404.jpg'
import b22 from '../../../Images/Interior/405.jpg'
import b23 from '../../../Images/Interior/408.jpeg'
import c21 from '../../../Images/Interior/406.jpg'
import c22 from '../../../Images/Interior/407.jpg'
import c23 from '../../../Images/Interior/103.jpg'



const Gallery = () => {

  useEffect(() => {window.scrollTo({top: 0, behavior: "smooth"})},[])

  const col11 = [a1,a2,a3]
  const col12 = [b1,b2,b3]
  const col13 = [c1,c2,c3]

  const col21 = [a21,a22,a23]
  const col22 = [b21,b22,b23]
  const col23 = [c21,c22,c23]

  // const col11 = [c11,c13,c14]
  // const col12 = [c21,c22,c24]
  // const col13 = [c16,c25]
  // const col11 = [c11,c12,c13,c14,c15,c16]
  // const col12 = [c21,c22,c24,c25,c26,c17]
  // const col13 = [c31,c32,c33,c34,c35,c27]

  return(
    <div id="gal-container">
      <div id="gal-header-cover"></div>
      <div className="gal-title">OUR GALLERY</div>
      <div className="gal-cont">
        <div className="gal-col">
        {
          col11.map((i) => {
            return(
              <img src={i} className="gal-img" />
            )
          })
        }
        </div>
        <div className="gal-col">
        {
          col12.map((i) => {
            return(
              <img src={i} className="gal-img" />
            )
          })
        }
        </div>
        <div className="gal-col">
        {
          col13.map((i) => {
            return(
              <img src={i} className="gal-img" />
            )
          })
        }
        </div>
      </div>

      <div className="gal-title">Empowering Our Team</div>
      <div className="gal-cont">
        <div className="gal-col">
        {
          col21.map((i) => {
            return(
              <img src={i} className="gal-img" />
            )
          })
        }
        </div>
        <div className="gal-col">
        {
          col22.map((i) => {
            return(
              <img src={i} className="gal-img" />
            )
          })
        }
        </div>
        <div className="gal-col">
        {
          col23.map((i) => {
            return(
              <img src={i} className="gal-img" />
            )
          })
        }
        </div>
      </div>
    </div>
  )
}

export default Gallery;