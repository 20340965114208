import React,{useEffect, useState, createContext} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './main.scss'
// import Header from '../Header/header'
import Header from '../Head'
import Footer from '../Footer/footer'

import { Outlet } from 'react-router-dom'

export const BranchContext = createContext({
  branchNow: null,
  setBranchNow: null
})  

const Main = () => {

  const nav = useNavigate()
  const loc = useLocation()
  const branchCache = localStorage.getItem('apc-branch')
  const [branchNow, setBranchNow] = useState(branchCache === null ? 'Kodai' : branchCache)

  useEffect(() => {
    nav('/')
  }, [branchNow])

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const changeHeight = (zoom,height1,height2,height3) => {
    // console.log('height: ',window.innerHeight, ' width: ', window.innerWidth);
    // console.log(zoom, height1, document.querySelector(`body`).style.zoom);
    console.log(zoom);
    document.querySelector(`body`).style.zoom = zoom;
    document.querySelector(`#home-box-one`).style.height = height1;
    document.querySelector(`#home-box-one .home-one-img`).style.height = height1;
    document.querySelector(`#home-box-one .home-one-img-height`).style.height = height1;
    document.querySelector(`#home-box-seven`).style.height = height2;
    document.querySelector(`#home-box-six`).style.height = height3;
  }

  useEffect(() => {
    // console.log(loc.pathname);
    if(loc.pathname == '/' && window.innerWidth > 1024) {
      if(window.innerHeight < 760 || window.innerWidth < 1360) {
        changeHeight('90%','50rem')
        if(window.innerHeight < 700 || window.innerWidth < 1290) {
          changeHeight('80%','50rem','70vh')
          if(window.innerHeight < 660 || window.innerWidth < 1100) {
            changeHeight('70%','50rem','35rem','40rem')
          }
        }
      }
    }
  })

  return(
    <div id="main-container">
      <BranchContext.Provider value={{
        branchNow: branchNow,
        setBranchNow: setBranchNow
      }}>
        <Header />
        <div id="main-screens-container">
          <Outlet/>
        </div>
        <Footer />
      </BranchContext.Provider>
    </div>
  )
}

export default Main