import React, {useState, useEffect} from 'react'
import './health.scss'
import styled from 'styled-components'

const Health = () => {

  const [one, setOne] = useState(false)
  const [two, setTwo] = useState(false)
  const [three, setThree] = useState(false)
  const [four, setFour] = useState(false)

  const pkgOne = new IntersectionObserver(e => {e.forEach(i => {if(i.isIntersecting) { setOne(true) }})})
  const pkgTwo = new IntersectionObserver(e => {e.forEach(i => {if(i.isIntersecting) { setTwo(true) }})})
  const pkgThree = new IntersectionObserver(e => {e.forEach(i => {if(i.isIntersecting) { setThree(true) }})})
  const pkgFour = new IntersectionObserver(e => {e.forEach(i => {if(i.isIntersecting) { setFour(true) }})})

  useEffect(() => {
    pkgOne.observe(document.querySelector(`#pkg1`))
    pkgTwo.observe(document.querySelector(`#pkg2`))
    pkgThree.observe(document.querySelector(`#pkg3`))
    pkgFour.observe(document.querySelector(`#pkg4`))
    window.scrollTo({top: 0, behavior: "smooth"})
  },[])

  return(
    <div id="health-container">
      <div id="health-header-cover"></div>
      <div id="health-title">HEALTH PACKAGES</div>
      {/* <div id="health-subtitle">
        <div className="health-sub1">Are you above the age of 30?</div> 
        <div className="health-sub1">Do you have a family history of Hypertension or Diabetes?</div> 
        <div id="health-sub2">Take advantage of our Discounted Special Health Package!</div>  
      </div> */}
    
      <div className="pkg-container" id="pkg1">
        <div className="pkg-left">
          <div className="pkg-title">Basic Health Package: 139 Riyal + Tax</div>
          <div className="pkg-subtitle">CONSULTATION, CBC, RBS/FBS, CHOLESTROL, TRIGLYCERIDES, CREATININE, URINE RE, ECG, SGPT</div>
          <div className="pkg-title">Hypertension/Diabetic Special Package 199 Riyal +Tax</div>
          <div className="pkg-subtitle">CONSULTATION, HBA1C, LIPID PROFILE, CREATININE, URINE RE</div>
          <div className="pkg-title">Pregnancy Health Package 179 Riyal +Tax  </div>
          <div className="pkg-subtitle">CONSULTATION, USG OF PREGNANCY PROFILE, HEMOGLOBIN, BLOOD GROUP, BLOOD SUGAR, URINE RE, VDRL</div>
        </div>
        {one && <div className="pkg-right">
          <div className="pkg-right-img">
            <div className="pkg-right-img-sub1">Are you above the age of 30?</div> 
            <div className="pkg-right-img-sub1">Do you have a family history of Hypertension or Diabetes?</div> 
            <div className="pkg-right-img-sub2">Take advantage of our Discounted Special Health Package!</div>    
          </div>  
        </div>}
      </div>
    
      <div className="pkg-container" id="pkg2">
        <div className="pkg-left">
          <div className="pkg-title">استفد من الباقة الصحية المخفضة الخاصة بنا ۱۳۹ ريال + الضريبة</div>
          <div className="pkg-subtitle">كشف الطبيب الدهون و الدهون الثلاثية, سكر الدم, صورة الدم, انزيمات الكبد, فحص البول, فحص الكرياتنيين وتخطيط القلب</div>
          <div className="pkg-title">باقة خاصة بمرض ارتفاع ضغط الدم / السكري 199 ريال + الضريبة</div>
          <div className="pkg-subtitle">كشف الطبيب, باقة الدهون, فحص تراكمي السكر, فحص الكرياتنيين وفحص البول</div>
          <div className="pkg-title">برنامج متابعة الحمل ۱۷۹ ريال + الضريبة</div>
          <div className="pkg-subtitle">VDRL كشف الطبيب, اشعة تلفزيونيه, تحليل صورة الدم و فصيلة الدم, تحليل السكر و البول, تحليل ال</div>
        </div>
        {two && <div className="pkg-right">
          <div className="pkg-right-img">
            <div className="pkg-right-img-sub1">هل عمرك فوق الثلاثين ؟</div> 
            <div className="pkg-right-img-sub1">هل لديك تاريخ مرضى من ارتفاع ضغظ الدم او مرض السكري ؟</div> 
            {/* <div className="pkg-right-img-sub2">استفد من الباقة الصحية المخفضة الخاصة بنا ۱۳۹ ريال + الضريبة</div>       */}
          </div>  
        </div>}
      </div>

      <div className="pkg-container" id="pkg3">
        <div className="pkg-left">
          <div className="pkg-title">বিশেষ স্বাস্থ্য প্যাকেজ ১৩৯ রিয়াল+ট্যাক্স</div>
          <div className="pkg-subtitle">ডাক্তারের পরামর্শ, সিবিসি, আরবিএস/এফবিএস, কোলেস্টেরল, ট্রাইগ্লিসারাইড, ক্রিয়েটিনিন, ইউরিন আর/ই, ইসিজি, এসজিপিটি</div>
          <div className="pkg-title">হাইপারটেনশন/ডায়াবেটিক স্পেশাল প্যাকেজ ১৯৯ রিয়াল+ট্যাক্স</div>
          <div className="pkg-subtitle">ডাক্তারের পরামর্শ,এইচবিএওয়ানসি, লিপিড প্রোফাইল, ক্রিয়েটিনিন, ইউরিন আর/ই </div>
          <div className="pkg-title">প্রসূতি সেবা প্যাকেজ ১৭৯ রিয়াল+ট্যাক্স</div>
          <div className="pkg-subtitle">ডাক্তারের পরামর্শ, আল্ট্রাসনোগ্রাফি অফ প্রেগনেনসী প্রোফাইল, হিমোগ্লোবিন, ব্লাড সুগার, ব্লাড গ্রুপিং, ইউরিন আর/ই ভিডিআরএল</div>
        </div>
        {three && <div className="pkg-right">
          <div className="pkg-right-img">
            <div className="pkg-right-img-sub1">আপনার কি উচ্চ রক্তচাপ বা ডায়াবেটিসের?</div> 
            <div className="pkg-right-img-sub1">আপনার বয়স কি ৩০ এর উপরে?</div> 
            <div className="pkg-right-img-sub2">পারিবারিক ইতিহাস আছে আমাদের ডিসকাউন্টযুক্ত বিশেষ স্বাস্থ্য প্যাকেজের সুবিধা নিন ।</div>      
          </div>  
        </div>}
      </div>
      
      <div className="pkg-container" id="pkg4">
        <div className="pkg-left">
          <div className="pkg-title">പ്രത്യേക ആരോഗ്യ പാക്കേജ് 139 റിയാൽ + Tax</div>
          <div className="pkg-subtitle">ഡോക്ടർ പരിശോധന,സിബിസി, ഷുഗർ, കൊളസ്ട്രോൾ, ട്രൈഗ്ലിസറൈഡ്സ്, ക്രീയേറ്റീനിൻ, യൂറിൻ, ഇസിജി, SGPT</div>
          <div className="pkg-title">Hപ്രമേഹം / ഹൈപ്പർടെൻഷൻ പ്രത്യേക പാക്കേജ് 199 റിയാൽ + Tax </div>
          <div className="pkg-subtitle">ഡോക്ടർ പരിശോധന, HBA1C, ലിപിഡ് പ്രൊഫൈൽ, യൂറിൻ, ക്രീയേറ്റീനിൻ</div>
          <div className="pkg-title">ഗർഭകാല ആരോഗ്യ പാക്കേജ് 179 റിയാൽ + Tax</div>
          <div className="pkg-subtitle">ഡോക്ടർ പരിശോധന, USG PREGNANCY PROFILE, ഹീമോഗ്ലോബിൻ, ബ്ലഡ് ഗ്രൂപ്പ്, ഷുഗർ, യൂറിൻ, VDRL</div>
        </div>
        {four && <div className="pkg-right">
          <div className="pkg-right-img">
            <div className="pkg-right-img-sub1">നിങ്ങൾക്ക് മുപ്പത് വയസ്സിനു മുകളിലാണോ ?</div> 
            <div className="pkg-right-img-sub1">നിങ്ങൾക്ക് പാരമ്പര്യമായി പ്രമേഹവും ഹൈപ്പർടെൻഷനും ഉള്ളവരാണോ ?</div> 
            <div className="pkg-right-img-sub2">ഞങ്ങളുടെ പ്രത്യേക ഡിസ്കൗണ്ട് പാക്കേജുകൾ പ്രയോജനപ്പെടുത്തൂ</div>      
          </div>  
        </div>}
      </div>
    
    </div>
  )
}

export default Health;