import React, {useState, useEffect, useContext} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './insurance.scss';
import styled from 'styled-components';

import { BranchContext } from '../Main/main';


//kodai
import kOne from '../../../Images/Insurance/axa.jpg'
import kTwo from '../../../Images/Insurance/bupa.png'
import kThree from '../../../Images/Insurance/globemed.png'
import kFour from '../../../Images/Insurance/gulfunion.png'
import kFive from '../../../Images/Insurance/malath.jpg'
import kSix from '../../../Images/Insurance/medgulf.png'
import kSevenD from '../../../Images/Insurance/mednetD.png'
import kSeven from '../../../Images/Insurance/mednet.png'
import kEight from '../../../Images/Insurance/saico.png'
import kNine from '../../../Images/Insurance/snc.png'
import kTen from '../../../Images/Insurance/tawuniya.png'
import kEleven from '../../../Images/Insurance/tcs.png'
import kTwelve from '../../../Images/Insurance/rajhi.jpg'


const Insurance = () => {

  const loc = useLocation()
  const params = useParams();

  const branchData = useContext(BranchContext)
  useEffect(() => {
    setBranchOne(branchData.branchNow === 'Kodai' || branchData.branchNow === null ? true : false)
  }, [branchData.branchNow])

  const [branchOne, setBranchOne] = useState(true)
  const [phone, setPhone] = useState(window.innerWidth < 1024);

  useEffect(() => {window.scrollTo({top: 0, behavior: "smooth"})},[])

  return(
    <div id="inc-container">
      <div id="inc-header-cover"></div>
      <div id="inc-top-box">
        {/* <div className="inc-top-menu" onClick={() => {setBranchOne(true)}}>
          KODAI <ActiveBranch id="inc-top-menu-active" pos={branchOne}></ActiveBranch>
        </div>
        <div className="inc-top-menu" onClick={() => {setBranchOne(false)}}>AZIZIA</div> */}
        <div className="inc-top-left"><a href="tel:+966 0125380891">Contact us</a> to know more about our insurance policy</div>
      </div>

      {
        branchOne ?
        <div id="inc-bottom-box">
          <div className="inc-bottom-indi-box"><img src={kTwo}/></div>
          <div className="inc-bottom-indi-box"><img src={kThree}/></div>
          <div className="inc-bottom-indi-box"><img src={kFour}/></div>
          <div className="inc-bottom-indi-box"><img src={kFive}/></div>
          <div className="inc-bottom-indi-box"><img src={kSix}/></div>
          {phone ? <div className="inc-bottom-indi-box"><img src={kSeven}/></div>:
            <div className="inc-bottom-indi-box"><img src={kSevenD}/></div>
          }
          <div className="inc-bottom-indi-box"><img src={kEight}/></div>
          <div className="inc-bottom-indi-box"><img src={kNine}/></div>
          <div className="inc-bottom-indi-box"><img src={kTen}/></div>
          <div className="inc-bottom-indi-box"><img src={kEleven}/></div>
          <div className="inc-bottom-indi-box"><img src={kOne}/></div>
        </div> :
        <div id="inc-bottom-box">
          <div className="inc-bottom-indi-box aa"><img src={kEleven}/></div>
          {phone ? <div className="inc-bottom-indi-box"><img src={kSeven}/></div>:
            <div className="inc-bottom-indi-box"><img src={kSevenD}/></div>
          }
          <div className="inc-bottom-indi-box aa"><img src={kTen}/></div>
          <div className="inc-bottom-indi-box aa"><img src={kNine}/></div>
          <div className="inc-bottom-indi-box aa"><img src={kEight}/></div>
          <div className="inc-bottom-indi-box aa"><img src={kTwelve}/></div>
          <div className="inc-bottom-indi-box aa"><img src={kTwo}/></div>
        </div>
      }
    </div>
  )
}

export default Insurance;

const ActiveBranch = styled.div`
  left: ${props => props.pos ? "0.2rem" : "122%"}
`
