import React, {useState, useEffect, useRef, useContext} from 'react'
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import './index.scss'
import styled from 'styled-components'

import logo from '../../../Images/Header/logo2.png'
import menuIcon from '../../../Images/Icons/menu-white.svg'
import closeIcon from '../../../Images/Icons/close.svg'

import facebook from '../../../Images/Icons/facebook.svg'
import instagram from '../../../Images/Icons/instagram.svg'
import twitter from '../../../Images/Icons/twitter.svg'
import snapchat from '../../../Images/Icons/snapchat.svg'

import facebookP from '../../../Images/Footer/facebook.svg'
import instagramP from '../../../Images/Footer/instagram.svg'
import twitterP from '../../../Images/Footer/twitter.svg'
import tiktokP from '../../../Images/Footer/twitter.svg'

import callIcon from '../../../Images/Header/call.svg'
import emailIcon from '../../../Images/Header/mail.svg'

import Department from '../Departments/department';

//branch now context
import {BranchContext} from '../Main/main'


const Header = () => {

  const nav = useNavigate();
  const aboutRef = useRef();
  const loc = useLocation()

  const [isHome, setIsHome] = useState(true)
  useEffect(() => {
    if(loc.pathname === '/') setIsHome(true);
    else setIsHome(false);
  })

  useEffect(() => {window.scrollTo({top: 0, behavior: "smooth"})},[])
  const [phone, setPhone] = useState(window.innerWidth < 1024 ? true : false)

  const [atTop, setAtTop] = useState(true)
  const [shrinkHeader, setShrinkHeader] = useState(false)

  const [showTwo, setShowTwo] = useState(phone ? true : false)
  const [showMenu, setShowMenu] = useState(false)
  const [showMenuAnime, setShowMenuAnime] = useState(false)

  const [oneDrop,setOneDrop] = useState(false)
  const [oneDropA,setOneDropA] = useState(false)
  const [twoDrop,setTwoDrop] = useState(false) //not in use but dont delete
  const [twoDropA,setTwoDropA] = useState(false)

  const [twoDropP,setTwoDropP] = useState(false);
  const [twoDropAP,setTwoDropAP] = useState(false);

  var lastScroll = 0;

  const [dpt, setDpt] = useState(false)
  const [dptData, setDptData] = useState(0)

  useEffect(() => {

    if(oneDropA) setOneDrop(true);
    else setTimeout(() => {setOneDrop(false)}, 400);

    if(twoDropA) setTwoDrop(true);
    else setTimeout(() => {setTwoDrop(false)}, 400);

    if(twoDropAP) setTwoDropP(true);
    else setTwoDropP(false);

  },[oneDropA, twoDropA, twoDropAP])

  useEffect(() => {
    document.addEventListener('scroll', changeHeader)
  },[])

  const changeHeader = () => {
    let scrollingNow = Math.round(window.scrollY);
    if(scrollingNow > 40) setAtTop(true)
    if(scrollingNow > 40) setShrinkHeader(true)
    if(scrollingNow < 40) setTimeout(() => {setShrinkHeader(false)}, 100);
    if(scrollingNow < 40) setAtTop(false)
    
    // if(scrollingNow > 600) setShowTwo(false)
    // console.log(scrollingNow)
  }

  const closeMenu = () => {
    setShowMenuAnime(false);
    setTimeout(() => {setShowMenu(showMenu ? false : true);}, 1000);
  }


  //change branches on click
  const branchContext = useContext(BranchContext)
  const branchChange = (e) => {
    console.log(e.target.value)
    branchContext.setBranchNow(e.target.value)
    localStorage.setItem('apc-branch', `${e.target.value}`)
    window.location.reload()
  }

  //show branch drop down
  const [showBranch, setShowBranch] = useState(false)


  var headerContainer = document.querySelector('#header-container');

if (headerContainer) { 
  if (window.scrollY > 0) {
    headerContainer.classList.add('scrolled'); 
  } else {
    headerContainer.classList.remove('scrolled'); 
  }
}


  
  return(
    <div id="header-container">
      {
        !phone &&
        <div id="header-box-zero">
          <div className="header-zero-left-box">
            <a href="tel:+966 0125380891" className="header-zero-left-link">
              <img src={callIcon} className="header-zero-left-icon"/>
              <span className="header-zero-left-text">012 538 0891</span>
            </a>                
            <a href="mailto:info@asianpolyclinic.com" className="header-zero-left-link">
              <img src={emailIcon} className="header-zero-left-icon"/>
              <span className="header-zero-left-subtitle">info@asianpolyclinic.com</span>                
            </a>
            {/* <div className="header-zero-mid-box">
              <label className="header-zero-mid-box-left" for="hospitals">Branch :</label>
              <div className="header-zero-mid-box-right" onClick={() => {
                setShowBranch(true)
                setTimeout(() => { setShowBranch(false) }, 4000);
              }}>
                {branchContext.branchNow}
                {
                  showBranch && 
                  <div className="header-zero-branch-box">
                  {
                    branchContext.branchNow === 'Kodai' ? 
                    <div className="header-zero-branch-drop" onClick={() => {
                      branchContext.setBranchNow('Azizia')
                      localStorage.setItem('apc-branch', 'Azizia')
                      window.location.reload()
                    }}>Azizia</div> : 
                    <div className="header-zero-branch-drop" onClick={() => {
                      branchContext.setBranchNow('Kodai')
                      localStorage.setItem('apc-branch', 'Kodai')
                      window.location.reload()
                    }}>Kodai</div>
                  }
                  </div>
                }
              </div>
            </div> */}
          </div>
          
          <div id="header-zero-right-box-social">
            <a href="https://www.facebook.com/1420766728144346/posts/pfbid0CQTs8xgZSdT1oP9B44tzH41aXdz18PfuAkEzrmLfaiHMwcSLMj8AuDUzu1r5MoHQl/" target={'_blank'}><img src={facebook}  alt="facebook" className="header-zero-social-icon" /></a>
            <a href="https://www.instagram.com/p/Cf3kc0BPU7w/?igshid=YmMyMTA2M2Y=" target={'_blank'}><img src={instagram} alt="instagram" className="header-zero-social-icon" /></a>
            <a href="https://twitter.com/PolyclinicAsian/status/1547133088089550853?s=20&t=owqkVet0_67luUQsT13BSw" target={'_blank'}><img src={twitter} alt="twitter" className="header-zero-social-icon" /></a>
            <a href="https://story.snapchat.com/u/asianpolyclinic/CsNuBCW6ReWnITTv7rpnzgAAAb3dwZ2VyaWFnAYH2tlo_AYH2tloNAAAAAA?share_id=mv5Pt_GYb6c&locale=en-GB" target={'_blank'}><img src={snapchat} alt="snapchat" className="header-zero-social-icon" /></a>
          </div>
        </div>
      }
      {
        !phone &&
        <div className="header-box-one" id={shrinkHeader ? 'header-one-shrink' : 'header-one-default'}>
          {dpt && <Department data={dptData} setDpt={setDpt}/>}

          <div 
            id="header-one-left-box" 
            className={shrinkHeader ? "header-one-left-box-stretch" : "header-one-left-box-shrink"}
            onClick={() => {nav('/', {replace: true})}}
          >
            <div id="header-one-left-top-img"/>
            <div id="header-one-left-top">
              asian
            </div>
          </div>

          <div id="header-one-right-box">

            <div id="header-one-right-bottom">
              <div className="header-one-menu" onClick={() =>{
                nav('/', {replace:true})
              }}>HOME</div>
              <div className="header-one-menu" onClick={() => {nav('../about-us')}}>ABOUT US</div>
              <div className="header-one-menu" onMouseEnter={() => {setOneDropA(true)}} onMouseLeave={() => {setOneDropA(false)}}>
                OUR SPECIALTIES
                {oneDrop && 
                  <div className="header-one-drop-box">
                    <div className={oneDropA ? "header-one-drop-show" : "header-one-drop-hide"}>
                      <div className="header-one-drop-container">
                        <div className="header-one-drop-elements" onClick={() => {setDptData(0);setDpt(dpt ? false : true)}}>Internal and Family Medicine</div>  
                        <div className="header-one-drop-elements" onClick={() => {setDptData(1);setDpt(dpt ? false : true)}}>Pediatrics</div>
                        <div className="header-one-drop-elements" onClick={() => {setDptData(12);setDpt(dpt ? false : true)}}>General Medicine</div>
                        <div className="header-one-drop-elements" onClick={() => {setDptData(5);setDpt(dpt ? false : true)}}>ENT</div>
                        <div className="header-one-drop-elements" onClick={() => {setDptData(6);setDpt(dpt ? false : true)}}>Orthodontics</div>
                        <div className="header-one-drop-elements" onClick={() => {setDptData(8);setDpt(dpt ? false : true)}}>Orthopedics</div>
                        <div className="header-one-drop-elements" onClick={() => {setDptData(3);setDpt(dpt ? false : true)}}>Gynecology</div>
                      </div> 
                      <div className="header-one-drop-container">
                        <div className="header-one-drop-elements" onClick={() => {setDptData(2);setDpt(dpt ? false : true)}}>Dermetalogy</div>
                        <div className="header-one-drop-elements" onClick={() => {setDptData(9);setDpt(dpt ? false : true)}}>Pathology</div>  
                        <div className="header-one-drop-elements" onClick={() => {setDptData(4);setDpt(dpt ? false : true)}}>Dentistry</div>  
                        <div className="header-one-drop-elements" onClick={() => {setDptData(7);setDpt(dpt ? false : true)}}>Ophthalmology</div>  
                        <div className="header-one-drop-elements" onClick={() => {setDptData(10);setDpt(dpt ? false : true)}}>Radiology / X-Ray</div>  
                        <div className="header-one-drop-elements" onClick={() => {setDptData(11);setDpt(dpt ? false : true)}}>Other Laboratory Services</div>  
                        <div className="header-one-drop-elements" onClick={() => {console.log('insufficient data');}}>Pharmacy</div>  
                        {/* <div className="header-one-drop-elements" onClick={() => {setDptData(14);setDpt(dpt ? false : true)}}>Pharmacy</div>   */}
                      </div>                        
                    </div>
                  </div>  
                }
              </div>
              <div className="header-one-menu" onClick={() => {nav(`../doctors`,{state: {branchOne: true}})}}>OUR DOCTORS</div>
              <div className="header-one-menu" onClick={() => {nav('../health-packages')}}>HEALTH PACKAGES</div>
              <div className="header-one-menu" onClick={() => {nav('../insurance')}}>INSURANCE</div>
              <div className="header-one-menu" onClick={() => {nav('../gallery')}}>OUR GALLERY</div>
              <div className="header-one-menu" onClick={() => {
                document.querySelector(`#footer-bottom-container`).scrollIntoView({behavior: "smooth",block: "end",inline: "end"})
                }}>CONTACT US</div>
            </div>
          </div>
        </div>
      }
      {
        (showTwo || phone) &&
        <div id="header-box-two" className={showMenuAnime ? isHome ? "header-fix" : "header-fix-noblur" : isHome ? "header-abs" : "header-abs-noblur"}>
          <div className="header-two-left-box">
            <div className="header-two-img" onClick={() => {nav('/',{replace: true})}}/>
            <div className="header-two-logo" onClick={() => {nav('/',{replace: true})}}>asian</div>
          </div>
          {
            phone &&
            <div id="header-two-right-menu-box">
              <img src={showMenuAnime ? closeIcon : menuIcon} id="header-two-right-menu" onClick={() => {
                if(!showMenu) {
                  setShowMenu(true)
                  setShowMenuAnime(showMenuAnime ? false : true)
                }else {
                  setShowMenuAnime(false);
                  setTimeout(() => {setShowMenu(showMenu ? false : true);}, 1000);
                }
              }} />
            </div>
          }
          {
            (phone && showMenu) &&
            <div id="header-two-right-menu-drop" className={showMenuAnime ? "header-drop-menu" : "header-hide-menu"}>
              <div className="phone-menu-top">
                <div className="phone-two-menu-elements" onClick={() => {nav("/", {replace: true});closeMenu()}}>HOME</div>
                <div className="phone-two-menu-elements" onClick={() => {nav('../about-us');closeMenu()}}>ABOUT US</div>
                <div className="phone-two-menu-elements" onClick={() => {nav("../doctors");closeMenu()}}>OUR DOCTORS</div>
                <div className="phone-two-menu-elements" onClick={() => {nav('../health-packages');closeMenu()}}>HEALTH PACKAGES</div>
                <div className="phone-two-menu-elements" onClick={() => {nav('../insurance');closeMenu()}}>INSURANCE</div>
                <div className="phone-two-menu-elements" onClick={() => {nav('../gallery');closeMenu()}}>GALLERY</div>
                <div className="phone-two-menu-elements" onClick={() => {document.querySelector(`#footer-bottom-container`).scrollIntoView({behavior: "smooth"});closeMenu()}}>CONTACT US</div>
                {/* <div className="phone-two-menu-elements" onMouseEnter={() => {setTwoDropAP(true)}} onMouseLeave={() => {setTwoDropAP(false)}}>
                  BRANCH
                  {twoDropP && 
                    <div className="phone-two-menu-drop-box">
                      <div className={twoDropAP ? "phone-two-menu-dropp phone-two-menu-drop-show" : "phone-two-menu-dropp phone-two-menu-drop-hide"}>
                        <div className="phone-two-menu-drop-elements" value='Kodai' onClick={(e) => {
                          branchContext.setBranchNow('Kodai')
                          localStorage.setItem('apc-branch', 'Kodai')
                          window.location.reload()
                          closeMenu()
                        }}>Kodai</div>
                        <div className="phone-two-menu-drop-elements" value='Azizia' onClick={(e) => {
                          branchContext.setBranchNow('Azizia')
                          localStorage.setItem('apc-branch', 'Azizia')
                          window.location.reload()
                          closeMenu()
                        }}>Azizia</div>
                      </div>
                    </div>
                  }
                </div> */}
              </div>
              <div className="phone-menu-bottom">
                <a href="https://www.facebook.com/1420766728144346/posts/pfbid0CQTs8xgZSdT1oP9B44tzH41aXdz18PfuAkEzrmLfaiHMwcSLMj8AuDUzu1r5MoHQl/" target={'_blank'}><img src={facebook}  alt="facebook" className="header-one-social-icon" /></a>
                <a href="https://www.instagram.com/p/Cf3kc0BPU7w/?igshid=YmMyMTA2M2Y=" target={'_blank'}><img src={instagram} alt="instagram" className="header-one-social-icon" /></a>
                <a href="https://twitter.com/PolyclinicAsian/status/1547133088089550853?s=20&t=owqkVet0_67luUQsT13BSw" target={'_blank'}><img src={twitter} alt="twitter" className="header-one-social-icon" /></a>
                <a href="https://story.snapchat.com/u/asianpolyclinic/CsNuBCW6ReWnITTv7rpnzgAAAb3dwZ2VyaWFnAYH2tlo_AYH2tloNAAAAAA?share_id=mv5Pt_GYb6c&locale=en-GB" target={'_blank'}><img src={snapchat} alt="snapchat" className="header-one-social-icon" /></a>
              </div> 
            </div>
          }
        </div>
      }
    </div>
  )
}

export default Header;
