import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import './footer.scss'
import styled from 'styled-components'

import locationIcon from '../../../Images/Footer/location.svg'
import callIcon from '../../../Images/Footer/call.svg'
import mailIcon from '../../../Images/Footer/mail.svg'

const Footer = () => {

  const nav = useNavigate()
  const [phone, setPhone] = useState(window.innerWidth < 1024 ? true : false)

  console.log(localStorage.getItem('apc-branch'))

  const observer = new IntersectionObserver(e => {
    e.forEach(i => {
      if(i.isIntersecting) { 
        let a = document.querySelectorAll(`.footer-top-box`)
        a.forEach(i => {
          if(phone) {
            i.classList.add('footer-top-mount-p')
          }else {
            i.classList.add('footer-top-mount')
          }
        })
       }
    })
  })


  useEffect(() => {
    observer.observe(document.querySelector(`#footer-container`))
  },[])

  return(
    <div id="footer-container">
      <div id="footer-top-container">
        <div id="footer-top-container-box">
          <a className="footer-top-box" href="https://maps.app.goo.gl/LxBYc6iv8gR8nRUU6" target="_blank">
            <div className="footer-top-box-left">
              <img src={locationIcon} alt="" className="footer-top-box-left-icon" />
            </div>
            <div className="footer-top-box-right">
              <div className="footer-top-box-right-title">Find a clinic near you</div>
              <div className="footer-top-box-right-subtitle">Find Us On Map</div>
            </div>
          </a>
          <a className="footer-top-box" href="tel:+966 0125380891">
            <div className="footer-top-box-left">
              <img src={callIcon} alt="" className="footer-top-box-left-icon" />
            </div>
            <div className="footer-top-box-right">
              <div className="footer-top-box-right-title">Call us for enquiry</div>
              <div className="footer-top-box-right-subtitle">012 538 0891</div>
            </div>
          </a>
          <a className="footer-top-box" href="mailto:info@asianpolyclinic.com">
            <div className="footer-top-box-left">
              <img src={mailIcon} alt="" className="footer-top-box-left-icon" />
            </div>
            <div className="footer-top-box-right">
              <div className="footer-top-box-right-title">Feel free to message us!</div>
              <div className="footer-top-box-right-subtitle">Send an Email</div>
            </div>
          </a>
        </div>
      </div>

      <div id="footer-mid-container" className="mid-index">
        <div className="footer-mid-box" id="footer-mid-one">
          <div className="footer-mid-title">CONTACT US</div>
          <div className="footer-mid-bottom">
            <div className="footer-mid-one-box">
            {
              localStorage.getItem('apc-branch') === 'Azizia' ? 
              <a className="footer-mid-one-right" href="https://maps.app.goo.gl/LxBYc6iv8gR8nRUU6" target="_blank">
                Asian Poly Clinic, Makkah, Azizia, KSA
              </a> :
              <a className="footer-mid-one-right" href="https://maps.app.goo.gl/LxBYc6iv8gR8nRUU6" target="_blank">
                Asian Poly Clinic, Makkah, Zahrat Kodai, KSA
              </a>
            }
            </div>
            <div className="footer-mid-one-box">
              <a className="footer-mid-one-right" href="tel:+966 0125380891">+966 012 538 0891</a>
            </div>
            <div className="footer-mid-one-box">
              <div className="footer-mid-one-right" href="mailto:info@asianpolyclinic.com">info@asianpolyclinic.com</div>
            </div>
          </div>
        </div>
        <div className="footer-mid-box">
          <div className="footer-mid-title">QUICK LINKS</div>
          <div className="footer-mid-bottom-two">
            <div className="footer-mid-bottom-box">
              <div className="footer-mid-bottom-elements" onClick={() => {nav('/')}}>Home</div>
              <div className="footer-mid-bottom-elements" onClick={() => {
                document.querySelector(`#home-box-nine`).scrollIntoView({behavior: "smooth", block: "center"})}}>About us</div>
              {/* <div className="footer-mid-bottom-elements">Our Specialities</div> */}
              <div className="footer-mid-bottom-elements" onClick={() => {nav(`../doctors`,{state: {branchOne: true}})}}>Our Doctors</div>
              <div className="footer-mid-bottom-elements" onClick={() => {
                document.querySelector(`#home-box-five`).scrollIntoView({behavior: "smooth", block: "center"})}}>Mission and Vision</div>
            </div>
            <div className="footer-mid-bottom-box">
              <div className="footer-mid-bottom-elements" onClick={() => {
                document.querySelector(`#home-box-four`).scrollIntoView({behavior: "smooth", block: "start"})}}>Departments</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../health-packages')}}>Health Packages</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../about-us')}}>Management</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../insurance')}}>Insurance</div>
            </div>
          </div>
        </div>
      </div>
      
      <div id="footer-bottom-container">
        <span>© {new Date().getUTCFullYear()} Asian Poly Clinic, Inc. All rights reserved</span>
        <span>Designed and Developed by <a href="https://chzapps.com">CHZAPPS INDIA</a></span>
      </div>
    </div>
  )
}

export default Footer;