import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Preloader from './Screen/Preloader/preloader'
import Main from './Screen/Main/Main/main'
import Home from './Screen/Main/Home/home'
import Insurance from './Screen/Main/Insurance/insurance'
import Doctors from './Screen/Main/Doctors/doctors'
import Health from './Screen/Main/Health/health';
import Management from './Screen/Main/Management/management';
import AboutUs from './Screen/Main/About/about';
import Gallery from './Screen/Main/Gallery/gallery';



function App() {
  
  return (
    <Router>
      <Routes>
        {/* <Route path="preloader" element={<Preloader />}/> */}
        <Route path="/" element={<Main />}>
          <Route exact index element={<Home />}/>
          <Route path="/insurance" element={<Insurance />}/>
          <Route path="/doctors" element={<Doctors />}/>
          <Route path="/health-packages" element={<Health />}/>
          <Route path="/management" element={<Management />}/>
          <Route path="/about-us" element={<AboutUs />}/>
          <Route path="/gallery" element={<Gallery />}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
