import React, {useState, useEffect} from 'react';
import './management.scss';

const Management = () => {

  useEffect(() => {window.scrollTo({top: 0, behavior: "smooth"})},[])

  return(
    <div id="mng-container">
      <div id="mng-header-cover"></div>

      <div id="mgn-bottom-box">
        <div id="mgn-bottom-one">
          <div className="mgn-bottom-indi">
            <div className="mgn-bottom-indi-bottom">
              <div className="mgn-bottom-indi-bottom-title">Thahir Ahmed</div>
              <div className="mgn-bottom-indi-bottom-subtitle">Chairman</div>
            </div>
          </div>
          <div className="mgn-bottom-indi">
            <div className="mgn-bottom-indi-bottom">
              <div className="mgn-bottom-indi-bottom-title">Sayed Thangal</div>
              <div className="mgn-bottom-indi-bottom-subtitle">Executive Director</div>
            </div>
          </div>
        </div>
        <div id="mgn-bottom-two">asian</div>
        <div id="mgn-bottom-three">
          <div className="mgn-bottom-indi">
            <div className="mgn-bottom-indi-bottom">
              <div className="mgn-bottom-indi-bottom-title">V.P Sharafudhin</div>
              <div className="mgn-bottom-indi-bottom-subtitle">Executive Directors</div>
            </div>
          </div>
          <div className="mgn-bottom-indi">
            <div className="mgn-bottom-indi-bottom">
              <div className="mgn-bottom-indi-bottom-title">Ibrahim Kutty</div>
              <div className="mgn-bottom-indi-bottom-subtitle">Head of Finance</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Management;
