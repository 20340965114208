import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import './home.css'
import './new.scss';
import styled from 'styled-components';
import Department from '../Departments/department';

import DOne from '../../../Images/slides/aid2.jpg';
import DTwo from '../../../Images/slides/pills.jpg';
// import DThree  from '../../../Images/CLI/sFive.jpg'; 
// import DThree  from '../../../Images/slides/med.jpg'; 
import DThree from '../../../Images/Home-one/nFour.jpeg';
import DFour from '../../../Images/slides/med.jpg';

import POne from '../../../Images/slides/aid2.jpg';
import PTwo from '../../../Images/CLI/sFive.jpg';
import PThree from '../../../Images/Home-one/nFour.jpeg';
import PFour from '../../../Images/slides/med.jpg';
// import POne  from '../../../Images/CLI/sSix.jpg'; 
// import PTwo  from '../../../Images/Home-one/nOne.jpg';
// import PThree  from '../../../Images/Home-one/gThree.jpg'; 
// import PFour  from '../../../Images/CLI/sFive.jpg'; 

import bannerdoc1 from "../../../Images/Dr/two.jpg";
import bannerdoc2 from "../../../Images/Dr/three.JPG";
import bannerdoc3 from "../../../Images/Dr/five.jpg";
import bannerdoc4 from "../../../Images/Dr/six.jpg";
import bannerdoc5 from "../../../Images/Dr/ten.JPG";
import bannerdoc6 from "../../../Images/Dr/eleven.JPG";
import bannerdoc7 from "../../../Images/Dr/twelve.JPG";
import bannerdoc8 from "../../../Images/Dr/eight.JPG";
import managmentlogo from "../../../Images/Header/logo1B.svg"


import mOne from '../../../Assets/vision-mission/mission.png';
import mTwo from '../../../Assets/vision-mission/vission.png';
import mThree from '../../../Assets/vision-mission/value.png';

const Home = () => {

  const nav = useNavigate();
  const aboutRef = useRef();

  const [phone, setPhone] = useState(window.innerWidth < 1024 ? true : false);
  const [num, setNum] = useState(true);
  const [oneImg, setOneImg] = useState(DThree);
  const [twoImg, setTwoImg] = useState(DFour);
  const [homeSix, setHomeSix] = useState(false);
  const [homeSeven, setHomeSeven] = useState(false);

  const [sevenObs, setSevenObs] = useState(false);
  const [threeObs, setThreeObs] = useState(false);
  const [nineObs, setNineObs] = useState(false);

  const [dpt, setDpt] = useState(false);
  const [dptData, setDptData] = useState(0);

  const sixHome = new IntersectionObserver(e => {
    e.forEach(i => {
      if (i.isIntersecting) { setHomeSix(true); }
      else { setHomeSix(false); }
    });
  });

  const sevenHome = new IntersectionObserver(e => {
    e.forEach(i => {
      if (i.isIntersecting) { setHomeSeven(true); }
      else { setHomeSeven(false); }
    });
  });

  const sevenObserver = new IntersectionObserver(e => {
    e.forEach(i => { if (i.isIntersecting) setSevenObs(true); });
  });

  const threeObserver = new IntersectionObserver(e => {
    e.forEach(i => { if (i.isIntersecting) setThreeObs(true); });
  });

  const nineObserver = new IntersectionObserver(e => {
    e.forEach(i => { if (i.isIntersecting) setNineObs(true); });
  });


  useEffect(() => {
    sixHome.observe(document.querySelector(`#home-box-six`));
    sevenObserver.observe(document.querySelector(`#home-box-seven-in-box`));
    sevenHome.observe(document.querySelector(`#home-box-five`));
    threeObserver.observe(document.querySelector(`#home-box-three-bottom`));
    nineObserver.observe(document.querySelector(`#home-box-nine-mid`));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!phone) {
      if (num) {
        setTimeout(() => { setNum(false); }, 5000);
        setOneImg(oneImg === DThree ? DOne : DThree);
      }
      else {
        setTimeout(() => { setNum(true); }, 5000);
        setTwoImg(twoImg === DFour ? DTwo : DFour);
      }
    } else {
      if (num) {
        setTimeout(() => { setNum(false); }, 5000);
        setOneImg(oneImg === PThree ? POne : PThree);
      }
      else {
        setTimeout(() => { setNum(true); }, 5000);
        setTwoImg(twoImg === PFour ? PTwo : PFour);
      }
    }
  }, [num]);


  //banner text//

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const banners = [
    {
      title: '25+ years of Medical Experience',
      text: 'We offer multi speciality healthcare services under one roof',
    },
    {
      title: 'Now accepting all major insurance cards',
    },
    {
      title: 'Wide variety of Lab tests ',
      text: 'MOH approved Pre-employment to Iqamah Baladiya tests',
    },
    {
      title: 'Now accepting all major insurance cards',
      text: 'We offer multi speciality healthcare services under one roof',
    },

  ];

  useEffect(() => {
    // Use setInterval to switch banners in a loop every 3 seconds
    const intervalTimer = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000);

    // Clear the interval timer when the component unmounts
    return () => clearInterval(intervalTimer);
  }, [banners.length]);



  useEffect(() => {

    const scrollers = document.querySelectorAll(".scroller");

    // If a user hasn't opted in for recuded motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          console.log(duplicatedItem);
          duplicatedItem.setAttribute("aria-hidden", true);
          duplicatedItem.setAttribute("class", "scroll-img");
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);




  return (
    <div id="home-container">

      {homeSix && <div id="home-box-zero-six"></div>}

      {homeSeven && <div id="home-box-zero-seven"></div>}

      {/* slides */}
      <div id="home-box-one">
        <div
          className="home-one-img"
          // style={{ backgroundImage: `url(${oneImg})` }}
          id={
            num === true ? "home-one-one-show" : "home-one-one-hide"
          } />
        <div
          className="home-one-img home-one-img-height"
          // style={{ backgroundImage: `url(${twoImg})` }}
          id={
            num === true ? "home-one-two-hide" : "home-one-two-show"
          } />
        <div id="home-box-one-top">
          <div id="home-box-one-top-box">

            {/* title */}
            {/* {(num && (oneImg === DOne || oneImg === POne)) && <div className="home-box-one-top-title">
              25+ years of <br /> Medical Experience
            </div>}
            {(!num && (twoImg === DTwo || twoImg === PTwo)) && <div className="home-box-one-top-title">
              Wide variety of Lab tests
            </div>}
            {(num && (oneImg === DThree || oneImg === PThree)) && <div className="home-box-one-top-title">
              Now accepting all major <br /> insurance cards
            </div>}
            {(!num && (twoImg === DFour || twoImg === PFour)) && <div className="home-box-one-top-title">
              24/7 Customer service
            </div>}
 */}
            {/* subtitle */}
            {/* {(num && (oneImg === DOne || oneImg === POne)) && <div className="home-box-one-top-subtitle">
              We offer multi speciality healthcare services under one roof
            </div>}
            {(!num && (twoImg === DTwo || twoImg === PTwo)) && <div className="home-box-one-top-subtitle">
              MOH approved Pre-employment to <br /> Iqamah Baladiya tests
            </div>}
            {(num && (oneImg === DThree || oneImg === PThree)) && <div className="home-box-one-top-subtitle"> */}
            {/* 24/7 Emergency Service for all your medical needs */}
            {/* </div>}
            {(!num && (twoImg === DFour || twoImg === PFour)) && <div className="home-box-one-top-subtitle">
              Get in touch with us to enquire about your <br /> preffered Doctor's availablity
            </div>} */}
          </div>
        </div>
      </div>

      {/* emergency */}



      <div className="image-slider">

        <div className='banner_heading'>
          <h3>{banners[currentBannerIndex].title}</h3>
          <p>{banners[currentBannerIndex].text}</p>
        </div>


        {/* <div className="image-slider-track">

          <div className="slide">
            <img className='banner_slide_img' src={bannerdoc1} />
            <div className="banner-overlay">
              <p>Dr. Abdul Qadir</p>
              <h4>Internist</h4>
            </div>
          </div>

          <div className="slide">
            <img className='banner_slide_img' src={bannerdoc2} />
            <div className="banner-overlay">
              <p>Dr.Mohammed Raihan</p>
              <h4>Dermatologist</h4>
            </div>
          </div>

          <div className="slide">
            <img className='banner_slide_img' src={bannerdoc3} />
            <div className="banner-overlay">
              <p>Dr. Abubakar Siddiqh</p>
              <h4>E.N.T Surgeon</h4>
            </div>
          </div>

          <div className="slide">
            <img className='banner_slide_img' src={bannerdoc4} />
            <div className="banner-overlay">
              <p>Dr. Sayed Rafiuddin</p>
              <h4>Orthodontist</h4>
            </div>
          </div>

          <div className="slide">
            <img className='banner_slide_img' src={bannerdoc5} />
            <div className="banner-overlay">
              <p>Dr. Abul Kashem</p>
              <h4>Pediatrician</h4>
            </div>
          </div>


        </div> */}

        <div className="scroller" data-direction="left" data-speed="fast">
          <div className="scroller__inner">
            <div className='scroll-img'>
              <div className="image-container">
                <img src={bannerdoc1} alt="" />
                <div className="banner-overlay">
                  <p className="overlay-text">Dr. Abdul Qadir</p>
                  <h3 className="overlay-subtext">Internist</h3>
                </div>
              </div>
            </div>
            <div className='scroll-img'>
              <img src={bannerdoc2} alt="" />
              <div className="banner-overlay">
                <p className="overlay-text">Dr.Mohammed Raihan</p>
                <h3 className="overlay-subtext">Dermatologist</h3>
              </div>
            </div>
            <div className='scroll-img'>
              <img src={bannerdoc3} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Abubakar Siddiqh</p>
                <h3 className="overlay-subtext">E.N.T Surgeon</h3>
              </div>
            </div>
            <div className='scroll-img'>
              <img src={bannerdoc4} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Sayed Rafiuddin</p>
                <h3 className="overlay-subtext">Orthodontist</h3>

              </div>
            </div>
            <div className='scroll-img'>
              <img src={bannerdoc5} alt="" />
              <div className="banner-overlay">
                <p className="overlay-subtext">Dr. Abul Kashem</p>
                <h3 className="overlay-subtext"management_logo>Pediatrician</h3>

              </div>
            </div>
          </div>
        </div>





      </div>



      {/* <div id="home-box-two">
        <div className="home-box-two-box">
          <div className="home-box-two-box-title">Specialist Doctors's</div>
          <div className="home-box-two-box-subtitle">
            Choose from our wide variety of specialists of all kind. Consulting hours for specialists are from morning and evening shifts only
          </div>
          <div className="home-box-two-box-btn">
            <a href="tel:+966 0125380891">MESSAGE US</a>
          </div>
        </div>
        <div className="home-box-two-box">
          <div className="home-box-two-box-title">General Practioner</div>
          <div className="home-box-two-box-subtitle">
            Our General Practioners range from a diverse set on nationalities.  GP doctors are available round the clock 24/7
          </div>
          <div className="home-box-two-box-btn">
            <a href="tel:+966 0125380891">MAKE A CALL</a>
          </div>
        </div>
        <div className="home-box-two-box">
          <div className="home-box-two-box-title">Iqamah & Baladiya Department</div>
          <div className="home-box-two-box-subtitle">
            We have special packages available for our Corporate clients and we also accept the customers for screening on a 24/7 basis
          </div>
          <div className="home-box-two-box-btn">
            <a href="mailto:info@asianpolyclinic.com">EMAIL US YOUR ENQUIRY</a>
          </div>
        </div>
      </div>  */}

      {/* about-us */}
      <div id="home-box-nine" ref={aboutRef}>
        <div id="home-box-nine-mid">
          {(!phone && nineObs) && <div id="home-box-nine-mid-right">
            <div className="home-box-nine-mid-right-img" />
          </div>}
          {nineObs && <div id="home-box-nine-mid-left">
            <div id="home-box-nine-mid-title">ABOUT US</div>
            {phone && <div id="home-box-nine-mid-left-img">
              <div id="home-box-nine-img-relative">
                <div className="home-box-nine-mid-img" />
              </div>
            </div>}
            <div id="home-box-nine-mid-subtitle">
              Asian Polyclinic is an organization that drives the standards of primary health care to the new heights. The journey began in the year 1995 by launching our first Medical Clinic in Makkah, Misfalah. A relentless striving for service excellence and an all-important focus on quality perfection often drives us with new vigor and vitality. Our Clinics are the most coveted destinations for the people since our slogan is ‘Finest healthcare in best affordability’ and our pledge is 'Access to wellness to all'. We stress on improving the health of the community by setting up the standard of excellence in medical care, research, and clinical care. Aiming a patient-centric care, the hospital is utilizing enhanced lifesaving technologies for enhancing cure.
              <br /><br />
              Through our 15+ specialties, we provide in-depth expertise in the spectrum of advance medical and surgical interventions. We assure our services in the Departments of Internal Medicine, Gynaecology, Paediatrics, General Medicine, ENT, Dermatology, Ophthalmology, Dental Surgery and much more. We have some of the best specialty doctors from arround the world, they bring years of experience and offer evidence based treatment to ensure the best care for you.
              {/* <br /><br /> */}
              {/* We also have our branches in Kodai and Azizia. Click on the link to know more. */}
            </div>

            <div id="home-box-nine-mid-btn" onClick={() => { nav(`../about-us`); }}>READ MORE</div>
          </div>}
        </div>
      </div>

      {/* management */}
      <div className='management_section'>
        <div id="home-box-ten">
          {/* <div id="home-box-ten-title">OUR MANAGEMENT</div> */}
          <div id="home-box-ten-top">
            <div className='management_logo'>
              <img src={managmentlogo}></img>
              <h4>asian</h4>
            </div>
            <div id="home-box-ten-top-one">
              <div id="home-box-ten-top-one-title">OUR MANAGEMENT</div>
            </div>
          </div>
          {!phone &&
            <div id="home-box-ten-top-two">
              <div id="home-box-ten-top-two-btn" onClick={() => { nav(`../about-us`); }}>VIEW ALL</div>
            </div>
          }


          <div id="home-box-ten-bottom">
            <div id="ten-bottom-one">
              <div className="home-box-ten-indi">
                <div className="home-box-ten-indi-bottom">
                  <div className="home-box-ten-indi-bottom-title">Thahir Toufiq Ali Al Halabi</div>
                  <div className="home-box-ten-indi-bottom-subtitle">Chairman</div>
                </div>
              </div>
              <div className="home-box-ten-indi">
                <div className="home-box-ten-indi-bottom">
                  <div className="home-box-ten-indi-bottom-title">Sayed Thangal</div>
                  <div className="home-box-ten-indi-bottom-subtitle">Executive Director</div>
                </div>
              </div>
            </div>
            <div className='management_content'>
              <h4>Your Trusted Healthcare Provider</h4>
              <p>We are dedicated to serving the healthcare needs of our community with excellence, compassion, and cutting-edge medical expertise. With a legacy of experienced in providing quality healthcare services, we have earned the trust of patients and their families.<br></br><br></br>
                Our mission is to enhance the well-being of our patients by delivering personalized, patient-centered care. Our experienced and caring medical professionals work tirelessly to ensure your comfort and recovery. We take pride in our state-of-the-art facilities, advanced medical technology, and a commitment to maintaining the highest standards of quality and safety.
                Your health and wellness are our top priorities, and we are here to support you on your healthcare journey.<br></br><br></br>
              </p>
            </div>
            <div id="ten-bottom-three">
              <div className="home-box-ten-indi">
                <div className="home-box-ten-indi-bottom">
                  <div className="home-box-ten-indi-bottom-title">V.P Sharfulddeen</div>
                  <div className="home-box-ten-indi-bottom-subtitle">Executive Director</div>
                </div>
              </div>
              <div className="home-box-ten-indi">
                <div className="home-box-ten-indi-bottom">
                  <div className="home-box-ten-indi-bottom-title">Ibrahim Kutty</div>
                  <div className="home-box-ten-indi-bottom-subtitle">Head of Finance</div>
                </div>
              </div>
            </div>
          </div>

          {phone &&
            <div id="home-box-ten-phone-btn-box">
              <div id="home-box-ten-phone-btn" onClick={() => { nav(`../about-us`); }}>VIEW ALL</div>
            </div>
          }
        </div>
      </div>

      {/* mission-vision-value */}
      <div id="home-box-five">
        <div id="home-box-five-container">
          <div className="home-five-indi-box">
            <img src={mOne} className="home-five-indi-icon" />
            <div className="home-five-indi-title">OUR MISSION</div>
            <div className="home-five-indi-subtitle">To enrich our distinguished services and optimize the healthcare environment for creating a delightful experience to one and all.</div>
            {/* <div className="home-five-indi-btn">Read More</div> */}
          </div>
          <div className="home-five-indi-box">
            <img src={mTwo} className="home-five-indi-icon" />
            <div className="home-five-indi-title">OUR VISION</div>
            <div className="home-five-indi-subtitle">To provide affordable and reliable healthcare through our distinctive services and achieve a global presence in the hospitatlity sector by being within our system</div>
            {/* <div className="home-five-indi-btn">Read More</div> */}
          </div>
          <div className="home-five-indi-box">
            <img src={mThree} className="home-five-indi-icon" />
            <div className="home-five-indi-title">OUR VALUE</div>
            <div className="home-five-indi-subtitle">Delivering our professional best, providing the highest quality of services, being maximal in reliability and be the benchmark in the industry.</div>
            {/* <div className="home-five-indi-btn">Read More</div> */}
          </div>
        </div>
      </div>

      {/* departments */}
      <div id="home-box-four">
        {dpt && <Department data={dptData} setDpt={setDpt} />}
        <div id="home-box-four-top">
          <div id="home-box-four-top-title">OUR DEPARTMENTS</div>
          <div id="home-box-four-top-subtitle">
            We offer multi-speciality healthcare services under one roof
          </div>
        </div>
        <div id="home-box-four-bottom">

          <div className="home-box-four-bottom-box">
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(0); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/1.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">INTERNAL</div>
              <div className="home-box-four-bottom-box-indi-subtitle">
                Providing treatment for adult age problems that are then treated by doctors specialized in the field.</div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(1); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/2.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">PEDIATRICS</div>
              <div className="home-box-four-bottom-box-indi-subtitle">
                Provides best medical care for you child, preventing diseases, nutritioning back to health upto a legal age.
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(2); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/3.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">DERMATOLOGY</div>
              <div className="home-box-four-bottom-box-indi-subtitle">
                APC’s Dermatology Department provides expert cosmetic care to patients.
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(3); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/4.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">GYNECOLOGY</div>
              <div className="home-box-four-bottom-box-indi-subtitle">
                APC's gynecology department provides the best package health care for your loved ones.
              </div>
            </div>
            {
              phone &&
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(8); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/9.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">ORTHOPEDICS</div>
              </div>
            }
            {
              phone &&
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(9); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/10.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">PATHOLOGY</div>
              </div>
            }
          </div>

          <div className="home-box-four-bottom-box">
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(4); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/5.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">DENTISTRY</div>
              <div className="home-box-four-bottom-box-indi-subtitle">
                The smiles we recive, tells the story of the happy customers we have, of our decade long service.
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(5); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/6.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">ENT</div>
              <div className="home-box-four-bottom-box-indi-subtitle">
                Our board certified audiologists, can perform hearing tests to help find a cause.
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(6); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/7.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">ORTHODONTICS</div>
              <div className="home-box-four-bottom-box-indi-subtitle">
                The branch of dentistry concerned with growth and development of orofacial structures.
              </div>
            </div>
            <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(7); setDpt(dpt ? false : true); }}>
              <div className="home-box-four-bottom-box-indi-icon">
                <img src={require('../../../Images/Departments/Black/8.png')} />
              </div>
              <div className="home-box-four-bottom-box-indi-title">OPHTHALMOLOGY</div>
              <div className="home-box-four-bottom-box-indi-subtitle">
                Ophthalmologists are trained to perform eye tests, diagnose, treat and prescribe medications.
              </div>
            </div>
            {
              phone &&
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(10); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/11.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">RADIOLOGY</div>
              </div>
            }
            {
              phone &&
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(11); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/12.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">OTHER SERVICES</div>
              </div>
            }
          </div>

          {
            !phone &&
            <div className="home-box-four-bottom-box">
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(8); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/9.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">ORTHOPEDICS</div>
                <div className="home-box-four-bottom-box-indi-subtitle">
                  Helping in encourageing development and facilitate recovery, enabling people to stay in work.
                </div>
              </div>
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(9); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/10.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">PATHOLOGY</div>
                <div className="home-box-four-bottom-box-indi-subtitle">
                  The caring the injured, diagnosis of disease through the examination of surgically removed organs.
                </div>
              </div>
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(10); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/11.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">RADIOLOGY</div>
                <div className="home-box-four-bottom-box-indi-subtitle">
                  With our latest tech in the industry, we provide the best and efficient results for our services.
                </div>
              </div>
              <div className="home-box-four-bottom-box-indi" onClick={() => { setDptData(11); setDpt(dpt ? false : true); }}>
                <div className="home-box-four-bottom-box-indi-icon">
                  <img src={require('../../../Images/Departments/Black/12.png')} />
                </div>
                <div className="home-box-four-bottom-box-indi-title">OTHER SERVICES</div>
                <div className="home-box-four-bottom-box-indi-subtitle">
                  We provide other laboratory services like PCR test, Iqama tests, Baladiya tests and more.
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      {/* doctors */}
      <div id="home-box-eight">
        <div id="home-box-eight-top">
          <div id="home-box-eight-top-one">
            <div id="home-box-eight-top-one-title">OUR DOCTORS</div>
            <div id="home-box-eight-top-one-subtitle">We have some of the best specialty doctors from arround the world, they bring years of experience and offer evidence based treatment to ensure the best care for you.</div>
          </div>
          {!phone &&
            <div id="home-box-eight-top-two">
              <div id="home-box-eight-top-two-btn" onClick={() => { nav(`../doctors`); }}>VIEW ALL</div>
            </div>
          }
        </div>
        <div id="home-box-eight-bottom">
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Abdul Qadir</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">Internist</div>
            </div>
          </div>
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Mohammed Raihan</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">Dermatologist</div>
            </div>
          </div>
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Abubakar Siddiqh</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">E.N.T Surgeon</div>
            </div>
          </div>
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Sayed Rafiuddin</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">Orthodontist</div>
            </div>
          </div>
          <div className="home-box-eight-bottom-indi">
            <div className="home-box-eight-bottom-indi-top"></div>
            <div className="home-box-eight-bottom-indi-bottom">
              <div className="home-box-eight-bottom-indi-bottom-title">Dr. Abul Kashem</div>
              <div className="home-box-eight-bottom-indi-bottom-subtitle">Pediatrician</div>
            </div>
          </div>
        </div>
        {phone &&
          <div id="home-box-eight-phone-btn-box">
            <div id="home-box-eight-phone-btn" onClick={() => { nav(`../doctors`); }}>VIEW ALL</div>
          </div>
        }
      </div>

      {/* insurence */}
      <div id="home-box-seven">
        <div id="home-box-seven-title">INSURANCE AFFILIATES</div>
        <div id="home-box-seven-in-box">
          {
            sevenObs &&
            <div id="home-box-seven-in-anime">
              <div className="home-seven-in-indi"></div>
              <div className="home-seven-in-indi"></div>
              <div className="home-seven-in-indi"></div>
              <div className="home-seven-in-indi"></div>
              <div className="home-seven-in-indi"></div>
            </div>
          }
        </div>
        {/* <div id="home-box-seven-btn" onClick={() => {nav(`../insurance/${"kodai"}`,{state: {branchOne: true}})}}>VIEW MORE</div> */}
        <div id="home-box-seven-btn" onClick={() => { nav(`../insurance`, { state: { branchOne: true } }); }}>VIEW MORE</div>
      </div>

      {/* facilities */}
      <div id="home-box-three">
        <div id="home-box-three-top">
          <div id="home-box-three-top-title">OUR FACILITIES</div>
          <div id="home-box-three-top-subtitle">
            We have the best facilities and technology to occupy in the sector in our hands to your hospitatlity.
          </div>
        </div>
        <div id="home-box-three-bottom">
          <div id="home-box-three-bottom-top">
            <div id="home-box-three-bottom-top-left">
              <div className="home-box-three-bottom-img" id="home-three-one"></div>
              <div id="home-box-three-bottom-top-left-top">
                {
                  threeObs &&
                  <div className="home-box-three-text-anime-one">
                    Asian Poly Clinic always keeps up with the latest technologies to satisfy your needs. We make sure that our patients can experience the warm hospitatlity within an affordable price.
                  </div>
                }
              </div>
            </div>
            {!phone && <div className="home-box-three-bottom-img" id="home-three-two"></div>}
            {!phone && <div className="home-box-three-bottom-img" id="home-three-three"></div>}
          </div>
          <div id="home-box-three-bottom-bottom">
            <div className="home-box-three-bottom-img" id="home-three-four"></div>
            {!phone && <div className="home-box-three-bottom-img" id="home-three-five"></div>}
            <div id="home-box-three-bottom-bottom-mid">
              {threeObs &&
                <div className="home-box-three-text-anime-two">
                  {/* The facilities provided within Asian Poly Clinic is made sure to be of the highest standards and quality available. We make no compromise in the ethics of service.  */}
                </div>
              }
            </div>
            {phone && <div className="home-box-three-bottom-img" id="home-three-five"></div>}
            {phone && <div className="home-box-three-bottom-img" id="home-three-six"></div>}
          </div>
          <div id="home-three-btn" onClick={() => { nav(`../gallery`); }}>VIEW MORE</div>
        </div>
      </div>

      {/* apps */}
      <div id="home-box-six">
        <div id="home-six-left">
          <div id="home-six-title">Introducing Our Asian App</div>
          {
            !phone &&
            <div className="home-six-logos">
              <div className="home-six-apps"></div>
              <div className="home-six-apps"></div>
            </div>
          }
          <div id="home-six-subtitle">Our Social interactive page, <br />Get in touch with our Doctor's anytime, from your choice of <br /> social media channel</div>
        </div>
        <div id="home-six-right">
          <div id="home-six-right-two"></div>
        </div>
        {
          phone &&
          <div className="home-six-logos">
            <div className="home-six-apps"></div>
            <div className="home-six-apps"></div>
          </div>
        }
      </div>

    </div>
  );
};

export default Home;