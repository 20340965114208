import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import './department.scss'

import closeBtn from '../../../Images/Icons/closeB.svg'
import dptData from './department.json'

import {BranchContext} from '../Main/main'

//kodai
import a1 from '../../../Images/Dr/one.JPG'
import a2 from '../../../Images/Dr/two.jpg'
import a3 from '../../../Images/Dr/three.JPG'
import a4 from '../../../Images/Dr/four.JPG'
import a5 from '../../../Images/Dr/five.jpg'
import a6 from '../../../Images/Dr/six.jpg'
import a7 from '../../../Images/Dr/seven.JPG'
import a8 from '../../../Images/Dr/eight.JPG'
import a9 from '../../../Images/Dr/nine.JPG'
import a10 from '../../../Images/Dr/ten.JPG'
import a11 from '../../../Images/Dr/eleven.JPG'
import a12 from '../../../Images/Dr/twelve.JPG'
import a13 from '../../../Images/Dr/thirteen.JPG'
import a14 from '../../../Images/Dr/fourteen.JPG'
import a15 from '../../../Images/Dr/fifteen.JPG'
import a16 from '../../../Images/Dr/sixteen.JPG'
import a17 from '../../../Images/Dr/nineteen.jpg'
import a18 from '../../../Images/Dr/eighteen.jpg'
import a19 from '../../../Images/Dr/seventeen.jpeg'
import a20 from '../../../Images/Dr/twenty.jpg'
import a21 from '../../../Images/Dr/twentyone.jpg'
import aplace from '../../../Images/Dr/place.png'

//azizia
import aa1 from '../../../Images/Dr/Azizia/24.JPG'
import aa2 from '../../../Images/Dr/Azizia/7.jpg'
import aa3 from '../../../Images/Dr/Azizia/27.JPG'
import aa4 from '../../../Images/Dr/Azizia/29.jpg'
import aa5 from '../../../Images/Dr/Azizia/8.JPG'
import aa6 from '../../../Images/Dr/place.png'
import aa7 from '../../../Images/Dr/Azizia/5.JPG'
import aa8 from '../../../Images/Dr/Azizia/4.jpg'
import aa9 from '../../../Images/Dr/Azizia/9.JPG'
import aa10 from '../../../Images/Dr/Azizia/6.jpg'

const Department = ({data,  setDpt}) => {

    const [close, setClose] = useState(false)
    const [img, setImg] = useState(0)

    const branchContext = useContext(BranchContext)
    const [branchId, setBranchId] = useState(branchContext.branchNow === 'Kodai' ? 101 : 102)
    const [nullDoctor, setNullDoctor] = useState(true);

    useEffect(() => {
        switch (data) {
            case 0: setImg([a2,aa2]);break;
            case 1: setImg([a10,aa3]);break;
            case 2: setImg([a3]);break;
            case 3: setImg([a17,aa5]);break; //no image
            case 4: setImg([aa1,a15,a12,a9,aa4]);break;
            case 5: setImg([a5]);break;
            case 6: setImg([a6]);break;
            case 7: setImg([a11]);break;
            case 8: setImg([aplace]);break;
            case 9: setImg([a16]);break;
            case 10: setImg([a14,aa10]);break;
            case 11: setImg([a7]);break;
            case 12: setImg([a18,a8,a13,a4,a1,a19,a20,a21,aplace,aa7,aa8,aa9]);break;
            default: setImg([aplace])
                break;
        }
    },[data])

    const closeDpt = () => {
        setClose(true);
        setTimeout(() => {setDpt(false)}, 500);
    }

    useEffect(() => {
        dptData.list[data].doctors.map(i => {
            if(i.branch_id === branchId) setNullDoctor(false)
        })
    }, [])

    return(
        <DPTCont anime={close} id="dpt-container">
            <DPT anime={close} id="dpt-box">
                <div id="dpt-box-top">
                    <div id="dpt-box-top-title">{dptData.list[data].title}</div>
                    <div id="dpt-box-close">
                        <img src={closeBtn} onClick={closeDpt}/>
                    </div>
                </div>
                <div id="dpt-box-mid">{dptData.list[data].subtitle}</div>
                <div id="dpt-box-bottom-title">Available staff</div>
                <div id="dpt-box-bottom">
                    <div id="dpt-box-scroll">
                    {
                        dptData.list[data].doctors.map((i,index) => {
                            if(i.branch_id === branchId) {
                                return(
                                    <div className="dpt-box-dr-box">
                                        <img src={img[index]} className="dpt-dr-img" />
                                        <div className="dpt-dr-title">{i.name}</div>
                                        <div className="dpt-dr-subtitle">{i.stat}</div>
                                        <div className="dpt-dr-subtitle">{i.branch}</div>
                                    </div>
                                )
                            }
                        })
                    } 
                    {
                        nullDoctor &&
                        <div className="dpt-box-no-value">
                            <h2>No Doctors Available !</h2>
                            <h3>Try another branch</h3>
                        </div>
                    }
                    </div>               
                </div>
            </DPT>
        </DPTCont>
    )
}

export default Department;

const DPTCont = styled.div`
    animation-name: ${props => props.anime ? 'close-dpt-cont' : 'open-dpt-cont'};
`

const DPT = styled.div`
    animation-name: ${props => props.anime ? 'close-dpt' : 'open-dpt'};
`







// pediatrics ent radiology neurology internal orthodontics dermatology orthopedics pathology dentistry opthalmology -them
// pediatrics ent radiology --------- internal ------------ dermatology orthopedics --------- dentistry opthalmology -we