import React, {useState, useEffect, useContext} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './doctors.scss';
import styled from 'styled-components';

//branch context
import { BranchContext } from '../Main/main';

//kodai
import d1 from '../../../Images/Dr/place.png'
import d2 from '../../../Images/Dr/eleven.JPG'
import d3 from '../../../Images/Dr/nine.JPG'
import d4 from '../../../Images/Dr/twelve.JPG'
import d5 from '../../../Images/Dr/fifteen.JPG'
import d6 from '../../../Images/Dr/place.png'
import d7 from '../../../Images/Dr/eight.JPG'
import d8 from '../../../Images/Dr/thirteen.JPG'
import d9 from '../../../Images/Dr/four.JPG'
import d10 from '../../../Images/Dr/one.JPG'
import d11 from '../../../Images/Dr/place.png'
import d12 from '../../../Images/Dr/place.png'
import d13 from '../../../Images/Dr/place.png'
import d14 from '../../../Images/Dr/sixteen.JPG'
import d15 from '../../../Images/Dr/seven.JPG'
import d16 from '../../../Images/Dr/fourteen.JPG'
import d17 from '../../../Images/Dr/two.jpg'
import d18 from '../../../Images/Dr/three.JPG'
import d19 from '../../../Images/Dr/five.jpg'
import d20 from '../../../Images/Dr/six.jpg'
import d21 from '../../../Images/Dr/ten.JPG'
import d22 from '../../../Images/Dr/twentyone.jpg'
import d23 from '../../../Images/Dr/eighteen.jpg'
import d24 from '../../../Images/Dr/nineteen.jpg'
import d25 from '../../../Images/Dr/twenty.jpg'
import d26 from '../../../Images/Dr/seventeen.jpeg'

//azizia
import aa1 from '../../../Images/Dr/Azizia/24.JPG'
import aa2 from '../../../Images/Dr/Azizia/7.jpg'
import aa3 from '../../../Images/Dr/Azizia/27.JPG'
import aa4 from '../../../Images/Dr/Azizia/29.jpg'
import aa5 from '../../../Images/Dr/Azizia/8.JPG'
import aa6 from '../../../Images/Dr/place.png'
import aa7 from '../../../Images/Dr/Azizia/5.JPG'
import aa8 from '../../../Images/Dr/Azizia/4.jpg'
import aa9 from '../../../Images/Dr/Azizia/9.JPG'
import aa10 from '../../../Images/Dr/Azizia/6.jpg'


const Doctors = () => {

  const loc = useLocation()
  const params = useParams();

  const branchData = useContext(BranchContext)
  useEffect(() => {
    setBranchOne(branchData.branchNow === 'Kodai' || branchData.branchNow === null ? true : false)
  }, [branchData.branchNow])

  const [branchOne, setBranchOne] = useState(true)
  const [phone, setPhone] = useState(window.innerWidth < 1024)

  useEffect(() => {window.scrollTo({top: 0, behavior: "smooth"})},[])

  return(
    <div id="dr-container">
      <div id="dr-header-cover"></div>
      {/* <div id="dr-top-box">
        <div className="dr-top-menu" onClick={() => {setBranchOne(true)}}>
          KODAI <ActiveBranch id="dr-top-menu-active" pos={branchOne}></ActiveBranch>
        </div>
        <div className="dr-top-menu" onClick={() => {setBranchOne(false)}}>AZIZIA</div>
      </div> */}

      {
        branchOne ?
        <div id="dr-bottom-box">
          <div className="dr-bottom-indi-box">
            <img src={d17} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Abdul Qadir</div>
              <div className="dr-bottom-indi-bottom-subtitle">Internist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d18} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Muhammed Raihan</div>
              <div className="dr-bottom-indi-bottom-subtitle">Dermetalogist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d19} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Abubakar Siddiqh</div>
              <div className="dr-bottom-indi-bottom-subtitle">E.N.T Surgeon</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d20} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Sayed Rafiuddin</div>
              <div className="dr-bottom-indi-bottom-subtitle">Orthodontist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d21} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr.  Abul Kashem</div>
              <div className="dr-bottom-indi-bottom-subtitle">Pediatrician</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d24} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Numeera Faryad</div>
              <div className="dr-bottom-indi-bottom-subtitle">Gynaecologist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d2} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Maqbool Ahmed</div>
              <div className="dr-bottom-indi-bottom-subtitle">Opthalmologist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d3} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Salim Al Sulami</div>
              <div className="dr-bottom-indi-bottom-subtitle">Dentist General</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d4} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Sheik Umar</div>
              <div className="dr-bottom-indi-bottom-subtitle">Dentist Genral</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d5} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Haseena N Kauser</div>
              <div className="dr-bottom-indi-bottom-subtitle">Dentist Genral</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d23} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Sayed Khalid</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d7} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Nayeemul Hoq</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d8} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Bilkis Akther</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d9} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Ali Qader</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d10} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Kazi Mohammed Salahuddin</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d26} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Dirarul Islam</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d25} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Jinnath S Chowdhury</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d22} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Niyas Pariyarath</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d14} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Shahin Patil</div>
              <div className="dr-bottom-indi-bottom-subtitle">Pathologist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d15} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Olayash Abdulrahman</div>
              <div className="dr-bottom-indi-bottom-subtitle">Laboratory Specialist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box">
            <img src={d16} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Rania Abdel Rahman</div>
              <div className="dr-bottom-indi-bottom-subtitle">Radiologist</div>
            </div>
          </div>
        </div> :

        <div id="dr-bottom-box">
          <div className="dr-bottom-indi-box aa">
            <img src={aa1} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Musaad Saeed</div>
              <div className="dr-bottom-indi-bottom-subtitle">Dentist (Medical Director)</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa2} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Hassan Abdul Razek</div>
              <div className="dr-bottom-indi-bottom-subtitle">Internist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa3} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Ali Saeed Alyefee</div>
              <div className="dr-bottom-indi-bottom-subtitle">Pediatric Consultant</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa4} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Mahmoud Hasaan</div>
              <div className="dr-bottom-indi-bottom-subtitle">Dentist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa5} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Sadia Naureen</div>
              <div className="dr-bottom-indi-bottom-subtitle">Gynecologist</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa6} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Saleh AbdulMuhsen</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa7} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Mozibul Hoque</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa8} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Ramsheed Abootty</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa9} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Mohammed Zahirul Alam</div>
              <div className="dr-bottom-indi-bottom-subtitle">General Practitioner</div>
            </div>
          </div>
          <div className="dr-bottom-indi-box aa">
            <img src={aa10} className="dr-indi-img"/>
            <div className="dr-bottom-indi-bottom">
              <div className="dr-bottom-indi-bottom-title">Dr. Tarek Mohammed Mansur</div>
              <div className="dr-bottom-indi-bottom-subtitle">Radiologist</div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Doctors;

const ActiveBranch = styled.div`
  left: ${props => props.pos ? "0.2rem" : "122%"}
`
