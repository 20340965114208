import React, {useState, useEffect, useRef} from 'react';
import './about.scss';

const AboutUs = () => {

  const aboutRef = useRef()

  const [phone, setPhone] = useState(window.innerWidth < 1024)
  const [nineObs, setNineObs] = useState(false)

  const nineObserver = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) setNineObs(true)})
  })

  useEffect(() => {
    nineObserver.observe(document.querySelector(`#abt-mid`))
    window.scrollTo({top: 0, behavior: "smooth"})
  },[])

  return(
    <div id="abt-container">
      <div id="abt-header-cover"></div>
      <div id="abt" ref={aboutRef}>
        <div id="abt-mid">
          {(!phone && nineObs) && <div id="abt-mid-right">
            <div className="abt-mid-right-img"/>  
          </div>}
          {nineObs && <div id="abt-mid-left">
            <div id="abt-mid-title">ABOUT US</div>
            {phone && <div id="abt-mid-left-img">
              <div id="abt-img-relative">
              <div className="abt-mid-img"/>  
              </div>
            </div>}
            <div id="abt-mid-subtitle">
            Asian Polyclinic is an organization that drives the standards of primary health care to the new heights. The journey began in the year 1995 by launching our first Medical Clinic in Makkah, Misfalah. A relentless striving for service excellence and an all-important focus on quality perfection often drives us with new vigor and vitality. Our Clinics are the most coveted destinations for the people since our slogan is ‘Finest healthcare in best affordability’ and our pledge is 'Access to wellness to all'. We stress on improving the health of the community by setting up the standard of excellence in medical care, research, and clinical care. Aiming a patient-centric care, the hospital is utilizing enhanced lifesaving technologies for enhancing cure.
            <br/><br/>
            Through our 15+ specialties, we provide in-depth expertise in the spectrum of advance medical and surgical interventions. We assure our services in the Departments of Internal Medicine, Gynaecology, Paediatrics, General Medicine, ENT, Dermatology, Ophthalmology, Dental Surgery and much more. We have some of the best specialty doctors from arround the world, they bring years of experience and offer evidence based treatment to ensure the best care for you.
            <br /><br />
            We also have our branches in Kodai and Azizia. Click on the link to know more.
            </div>
          </div>}
        </div>
        {nineObs && 
          <div id="abt-bottom">
            <div id="abt-bottom-title">Our Sister Concerns</div>
            <div id="sis-center">
              <div className="sis-cards">
                <div className="sis-cards-left">
                  <div className="sis-cards-left-logo"></div>
                </div>
                <div className="sis-cards-right">
                  <div className="sis-cards-right-title">Rafa Medical Center</div>
                  <div className="sis-cards-right-subtitle">Prince Majid St, Alkurnaish, Al Khobar, Saudi Arabia</div>
                  <div className="sis-cards-right-link"><a href="https://www.rafamedicals.com">rafamedicals.com</a></div>
                </div>
              </div>
              <div className="sis-cards">
                <div className="sis-cards-left">
                  <div className="sis-cards-left-logo"></div>
                </div>
                <div className="sis-cards-right">
                  <div className="sis-cards-right-title">Asian Medical Center</div>
                  <div className="sis-cards-right-subtitle">Majlis Al Taawon St, Al Wakrah, Qatar</div>
                  <div className="sis-cards-right-link"><a href="http://www.asianmedicalcentre.com/">asianmedicalcenter.com</a></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div id="abt-bottom-box-title">OUR MANAGEMENT</div>
      <div id="abt-bottom-box">
        <div id="abt-bottom-one">
          <div className="abt-bottom-indi">
            <div className="abt-bottom-indi-bottom">
              <div className="abt-bottom-indi-bottom-title">Thahir Toufiq Ali Al Halabi</div>
              <div className="abt-bottom-indi-bottom-subtitle">Chairman</div>
            </div>
          </div>
          <div className="abt-bottom-indi">
            <div className="abt-bottom-indi-bottom">
              <div className="abt-bottom-indi-bottom-title">Sayed Thangal</div>
              <div className="abt-bottom-indi-bottom-subtitle">Executive Director</div>
            </div>
          </div>
        </div>
        <div id="abt-bottom-two">asian</div>
        <div id="abt-bottom-three">
          <div className="abt-bottom-indi">
            <div className="abt-bottom-indi-bottom">
              <div className="abt-bottom-indi-bottom-title">V.P Sharfulddeen</div>
              <div className="abt-bottom-indi-bottom-subtitle">Executive Director</div>
            </div>
          </div>
          <div className="abt-bottom-indi">
            <div className="abt-bottom-indi-bottom">
              <div className="abt-bottom-indi-bottom-title">Ibrahim Kutty</div>
              <div className="abt-bottom-indi-bottom-subtitle">Head of Finance</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default AboutUs;
